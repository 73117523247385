.RichText {
  > *:first-child {
    margin-top: 0;
  }

  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 1rem;
  }

  > ul {
    list-style: none;
    padding-left: 0;

    > li {
      &::before {
        content: '- ';
      }
    }
  }

  a {
    color: black;

    &:hover {
      color: steelblue;
    }

    &:visited {
      // color: gray;
    }
  }
}
